<template>
  <el-main>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
      <el-form-item label="排序：">
        <el-input v-model="ruleForm.sort" placeholder="请输入内容" type="number"></el-input>
        <span style="margin-left: 20px" class="tips">数字越大，排名越靠前；如为空，排序默认认为添加时间越近越靠前</span>
      </el-form-item>
      <el-form-item label="商品名称：" prop="name">
        <el-input v-model="ruleForm.name" placeholder="请输入内容"></el-input>
      </el-form-item>
      <el-form-item label="商品副标题：" prop="subtitle">
        <el-input v-model="ruleForm.subtitle" placeholder="请输入内容"></el-input>
      </el-form-item>
      <el-form-item label="商品分类：" prop="classfiy_id">
        <el-select v-model="ruleForm.classfiy_id" placeholder="请选择商品分类">
          <el-option v-for="item in goodsClass" :key="item.id" :label="item.classify_name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="商品图片：" prop="picture">
        <ReadyUploadSource
          @getSource="getGoodsImg"
          @changeOrder="changeOrder"
          @removeThis="index => ruleForm.picture.splice(index, 1)"
          :isMany="true"
          :manyPath="ruleForm.picture"
          :isManyMax="10"
          :maxSize="1024 * 1024"
        ></ReadyUploadSource>
        <p class="tips">建议图片尺寸750px*750px，大小不超过1M，最多可以上传10张图片，其中第一张为主图</p>
      </el-form-item>
      <el-form-item label="商品库存：" prop="stock">
        <el-input v-model="ruleForm.stock" placeholder="请输入内容" type="number"></el-input>
      </el-form-item>
      <el-form-item label="积分商品类型：" prop="stock">
        <el-radio-group v-model="ruleForm.is_card">
          <el-radio :label="0">普通商品</el-radio>
          <el-radio :label="1">权益卡/黄金类</el-radio>
          <el-radio :label="2">油卡兑换</el-radio>
          <el-radio :label="3">话费兑换</el-radio>
          <el-radio :label="4">视听会员</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="积分价格：" prop="integral_amount">
        <div class="integralPrice">
          <div class="integral">
            <div class="name">
              <sup>*</sup>
              积分兑换价格
            </div>
            <el-input v-model="ruleForm.integral_amount" size="mini" type="number"></el-input>
          </div>
          <div class="add">+</div>
          <div class="price">
            <div class="name">支付价格</div>
            <el-input v-model="ruleForm.price" size="mini" type="number"></el-input>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="商品原价：" prop="prime_cost">
        <el-input v-model="ruleForm.prime_cost" placeholder="请输入内容" type="number"></el-input>
      </el-form-item>
      <el-form-item label="运费：">
        <el-input v-model="ruleForm.freight"></el-input>
        <span class="tips">填写"0"或不填写则代表免费</span>
      </el-form-item>
      <el-form-item label="是否上架：" prop="is_putaway">
        <el-radio-group v-model="ruleForm.is_putaway">
          <el-radio :label="1">上架</el-radio>
          <el-radio :label="0">下架</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="商品详情：" prop="details">
        <RichText :richTxt="ruleForm.details" @soninfo="val => (ruleForm.details = val)"></RichText>
      </el-form-item>
    </el-form>
    <Preservation @preservation="Preservation"></Preservation>
  </el-main>
</template>

<script>
import ReadyUploadSource from '@/components/readyUploadSource';
import RichText from '@/components/richText';
import Preservation from '@/components/preservation';
import _ from 'lodash';
export default {
  components: {
    ReadyUploadSource,
    RichText,
    Preservation,
  },
  data() {
    function checkPicture(rule, value, callback) {
      if (value.length === 0) {
        callback(new Error('上传商品图片'));
      } else {
        callback();
      }
    }
    return {
      goods_id: 0,
      goodsClass: [],
      ruleForm: {
        name: '',
        subtitle: '',
        picture: [],
        integral_amount: '',
        classfiy_id: '',
        price: '',
        prime_cost: '',
        stock: '',
        freight: '',
        details: '',
        is_putaway: 1,
        sort: '',
        is_card: 0,
      },
      rules: {
        name: [{ required: true, message: '请输入商品名称', trigger: 'blur' }],
        subtitle: [{ required: true, message: '请输入商品副标题', trigger: 'blur' }],
        classfiy_id: [{ required: true, message: '请选择商品分类', trigger: 'change' }],
        picture: [{ required: true, validator: checkPicture, trigger: 'change' }],
        stock: [{ required: true, message: '请输入库存', trigger: 'blur' }],
        integral_amount: [{ required: true, message: '请输入积分数', trigger: 'blur' }],
        prime_cost: [{ required: true, message: '请输入商品原价', trigger: 'blur' }],
        details: [{ required: true, message: '请输入商品详情', trigger: 'blur' }],
        is_putaway: [{ required: true }],
      },
    };
  },
  created() {
    this.getTypeList();
    if (this.$route.query.id) {
      this.goods_id = this.$route.query.id;
      this.getGoodsInfo();
    }
  },
  methods: {
    // 获取上传的商品图片
    getGoodsImg(val) {
      val.map(item => {
        this.ruleForm.picture.push(item.path);
      });
    },
    // 获取商品分类
    getTypeList() {
      this.$axios
        .post(this.$api.integral.integralClass.list, {
          is_show: 2,
        })
        .then(res => {
          this.goodsClass = res.result.list;
        });
    },
    // 更改商品图片顺序
    changeOrder(val) {
      let arr = [];
      val.map(item => {
        arr.push(item);
      });
      this.ruleForm.picture = arr;
    },
    // 编辑时获取商品详情
    getGoodsInfo() {
      this.$axios
        .post(this.$api.integral.integralGoods.info, {
          id: this.goods_id,
        })
        .then(res => {
          if (res.code == 0) {
            res.result.picture = JSON.parse(res.result.picture);
            for (const key in this.ruleForm) {
              this.ruleForm[key] = res.result[key];
            }
          } else {
            this.$message.message(res.msg);
          }
        });
    },
    Preservation() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          let obj = _.cloneDeep(this.ruleForm);
          if (this.goods_id) obj.id = this.goods_id;
          let path = this.goods_id ? this.$api.integral.integralGoods.edit : this.$api.integral.integralGoods.add;
          this.$axios.post(path, obj).then(res => {
            if (res.code == 0) {
              let str = this.goods_id ? '编辑成功' : '添加成功';
              this.$message.success(str);
              this.$router.push('/extension/pointsMall/pointGoods');
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
  min-height: 100%;
  padding-bottom: 80px;
  .el-form {
    .el-input,
    .el-select {
      width: 500px;
    }
    .tips {
      font-size: 12px;
      color: #999;
    }
    .integralPrice {
      display: flex;
      align-items: center;
      line-height: 20px;
      .el-input {
        width: 120px;
      }
      .add {
        margin: 0 20px;
      }
      .name {
        margin-bottom: 10px;
        sup {
          color: red;
        }
      }
    }

    .freight {
      display: flex;
      flex-direction: column;
      & > label:not(:last-child) {
        margin-bottom: 15px;
      }
      .el-input,
      .el-select {
        width: 150px;
        margin: 0 20px;
      }
      .el-select {
        width: 250px;
      }
    }
  }
}
</style>
